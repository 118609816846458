"use client"

import * as React from "react"
import { Command } from "lucide-react"
import { organizationIcons } from "@/components/app-sidebar"

type Team = {
  name: string;
  logo: React.ElementType;
  logoName: string;
  plan: string;
};

type TeamContextType = {
  activeTeam: Team | null;
  setActiveTeam: (team: Team | null) => void;
};

// 获取缓存的团队信息
const getCachedTeam = (): Team | null => {
  if (typeof window !== 'undefined') {
    try {
      const savedTeam = localStorage.getItem('activeTeam')
      if (savedTeam) {
        const parsed = JSON.parse(savedTeam)
        const logoComponent = organizationIcons.find(icon => 
          icon.name === parsed.logoName
        ) || Command;
        return {
          ...parsed,
          logo: logoComponent
        };
      }
    } catch (e) {
      console.error('Failed to parse cached team:', e)
    }
  }
  return {
    name: 'kinet',
    logo: Command,
    logoName: 'kinet',
    plan: 'Free'
  };
};

const TeamContext = React.createContext<TeamContextType | undefined>(undefined);

export function TeamProvider({ children }: { children: React.ReactNode }) {
  // 使用缓存的团队信息初始化状态
  const [activeTeam, setActiveTeam] = React.useState<Team | null>(() => getCachedTeam());

  // 当 activeTeam 变化时更新缓存
  React.useEffect(() => {
    if (activeTeam) {
      const teamToSave = {
        ...activeTeam,
        logoName: activeTeam.logoName
      };
      localStorage.setItem('activeTeam', JSON.stringify(teamToSave));
    }
  }, [activeTeam]);

  return (
    <TeamContext.Provider value={{ activeTeam, setActiveTeam }}>
      {children}
    </TeamContext.Provider>
  );
}

export function useTeam() {
  const context = React.useContext(TeamContext);
  if (context === undefined) {
    throw new Error('useTeam must be used within a TeamProvider');
  }
  return context;
}