"use client"

import * as React from "react"
import {
  BookOpen,
  Bot,
  Command,
  Frame,
  Map,
  PieChart,
  Settings2,
  SquareTerminal,
  Boxes,
  Building2,
  CircleUser,
  Factory,
  Landmark
} from "lucide-react"
import { useEffect, useState } from 'react';

import { NavMain } from "@/components/nav-main"
import { NavProjects } from "@/components/nav-projects"
import { NavUser } from "@/components/nav-user"
import { TeamSwitcher } from "@/components/team-switcher"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar"
import { getCurrentUser } from "@/utils/session"
import { title } from "process";

interface User {
  organizations?: string[];
  nickname?: string;
  username?: string;
  avatar?: string;
  access_token?: string;
  refresh_token?: string;
  userid?: string;
  [key: string]: string | string[] | undefined;
}

// 创建一个图标数组供选择
export const organizationIcons = [
  Command,
  Boxes,
  Building2,
  CircleUser,
  Factory,
  Landmark,
  Frame,
  PieChart
];

// // 获取缓存的组织信息
// const getCachedOrganization = () => {
//   if (typeof window !== 'undefined') {
//     const cached = localStorage.getItem('activeTeam')
//     if (cached) {
//       try {
//         const parsed = JSON.parse(cached)
//         return parsed.name
//       } catch (e) {
//         console.error('Failed to parse cached team:', e)
//       }
//     }
//   }
//   return 'kinet'
// }

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const [userData, setUserData] = React.useState({
    name: "yuwang",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  })

  // 使用 useMemo 优化初始化
  const initialTeams = React.useMemo(() => {
    const user = getCurrentUser() as User | undefined;
    if (user?.organizations && user.organizations.length > 0) {
      return user.organizations.map((orgName, index) => ({
        name: orgName,
        logo: organizationIcons[index % organizationIcons.length],
        logoName: organizationIcons[index % organizationIcons.length].name,
        plan: "Free"
      }));
    }
    return [{
      name: 'kinet',
      logo: Command,
      logoName: 'command',
      plan: "Free"
    }];
  }, []);

  const [teams, setTeams] = useState(initialTeams);

  // 用户数据更新
  React.useEffect(() => {
    const currentUser = getCurrentUser()
    if (currentUser) {
      setUserData({
        name: currentUser.nickname ?? "m",
        email: currentUser.username ?? "m@example.com",
        avatar: currentUser.avatar ?? "/avatars/shadcn.jpg",
      })
    }
  }, [])

  const data = {
    user: userData,
    teams: teams,
    navMain: [
      {
        title: "Draw",
        url: "#",
        icon: SquareTerminal,
        isActive: true,
        items: [
          {
            title: "Protein",
            url: "#",
          },
          {
            title: "Ligand",
            url: "#",
          },
        ],
      },
      {
        title: "Database",
        url: "#",
        icon: Bot,
        items: [
          {
            title: "pKa DB",
            url: "#",
          },
          {
            title: "Drug DB",
            url: "#",
          },
        ],
      },
      {
        title: "Documentation",
        url: "#",
        icon: BookOpen,
        items: [
          {
            title: "Introduction",
            url: "#",
          },
          {
            title: "Get Started",
            url: "#",
          },
          {
            title: "Tutorials",
            url: "#",
          },
          {
            title: "Changelog",
            url: "#",
          },
        ],
      },
      {
        title: "Settings",
        url: "#",
        icon: Settings2,
        items: [
          {
            title: "General",
            url: "#",
          },
          {
            title: "Team",
            url: "#",
          },
          {
            title: "Billing",
            url: "#",
          },
          {
            title: "Limits",
            url: "#",
          },
        ],
      },
    ],
    categories: [
      {
        title: "ADME/T",
        url: "/platform/calculate/lexprop",
        icon: Frame,
      },
      // {
      //   name: "MolGen",
      //   url: "/platform/calculate/lex3dgen",
      //   icon: PieChart,
      // },
      // {
      //   name: "Affinity",
      //   url: "/platform/calculate/lexaffinity",
      //   icon: Map,
      // },
      {
        title: "Tasks",
        url: "/platform/tasks",
        icon: Map,
      },
    ],
  }

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={data.teams} />
      </SidebarHeader>
      <SidebarContent className="overflow-y-auto scrollbar-thin scrollbar-thumb-zinc-300 scrollbar-track-transparent">
        {/* <NavProjects categories={data.categories} /> */}
        <NavMain categorie_name="Calculate" items={data.categories} />
        <NavMain categorie_name="Utilities" items={data.navMain} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
