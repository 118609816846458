import Cookies from 'js-cookie'

const cookieKey = 'access_token'
const storageKey = 'kinet-current-user'

export interface User {
  userid?: string;
  username?: string;
  nickname?: string;
  access_token?: string;
  refresh_token?: string;
  organizations?: string[];
  avatar?: string;
  [key: string]: string | string[] | undefined;
}

function getLocalStorage(): Partial<User> | undefined {
  const data = localStorage.getItem(storageKey)
  return data ? JSON.parse(data) : undefined
}

function setLocalStorage(data: Partial<User>): void {
  localStorage.setItem(storageKey, JSON.stringify(data))
}

export function getCurrentUser(): Partial<User> | undefined {
  const cookie = Cookies.get(cookieKey)
  return cookie ? getLocalStorage() : undefined
}

export function setCurrentUser(user: Partial<User>): void {
  if (user.access_token) Cookies.set(cookieKey, user.access_token)
  const current = getLocalStorage() ?? {}
  setLocalStorage({ ...current, ...user })
}

export function removeCurrentUser() {
  Cookies.remove(cookieKey)
  localStorage.removeItem(storageKey)
}
