'use client';

import api from "@/utils/api";
import { setCurrentUser } from "@/utils/session";
import Cookies from 'js-cookie';

export async function handleLogout() {
  try {
    // 调用登出接口
    try {
      await api.post('/user/logout');
    } catch (error) {
      // 即使登出 API 调用失败，我们仍然要清除本地状态
      console.warn('Logout API call failed:', error);
    }
    
    // 无论如何都清除本地状态
    // 清除用户信息
    setCurrentUser({});
    
    // 清除 cookie（如果有使用）
    Cookies.remove('token');
    
    // 清除 localStorage（如果有使用）
    localStorage.clear();
    
    // 重定向到登录页
    window.location.href = '/login';
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
}